<template>
  <div>
    <div class="title">
      <img class="title-bg" src="../../assets/img/title/title.png" alt />
      <div class="water">{{ title }}</div>
      <div v-if="score && score != null" class="point"><span>评分:</span> {{ score }}</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      },
      score: {
        type: String || Number,
        default: null
      }
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  * {
    display: flex;
    align-items: center;
  }
  .title {
    width: 19.2vw;
    height: 4vh;
    line-height: 4vh;
    position: relative;
    margin: 0;
    .title-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 19.2vw;
      height: 3.7vh;
    }
    .water {
      position: absolute;
      left: 1.7vw;
      width: 17vw;
      height: 3.7vh;
      line-height: 3.7vh;
      color: rgba(60, 212, 149, 1);
      font-family: 'PingFang SC';
      font-size: 1.8vh;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      .font-blueLeft-out {
        font-size: 1.5vh;
        color: rgba(255, 255, 255, 0.7);
        .font-blueLeft {
          padding-left: 4vw;
        }
      }
      .font-blue {
        color: rgba(60, 212, 149, 1);
      }
    }
  }

  .point {
    position: absolute;
    right: 1.8vh;
    color: rgba(60, 212, 149, 1);
    height: 3.7vh;
    line-height: 3.7vh;
    font-family: 'PingFang SC';
    font-size: 1.8vh;
    font-weight: 500;
    width: auto;
    background-image: url('../../assets/icon/swiper-right-hover.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 15%;

    span {
      font-size: 1.5vh;
      color: rgba(255, 255, 255, 0.7);
      padding-right: 10px;
    }
  }
</style>
