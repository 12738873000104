<template>
  <el-carousel :interval="4000" indicator-position="outside" arrow="always" :height="myht">
    <el-carousel-item v-for="(item, index) in picList" :key="index">
      <!-- <img :src="item.url" alt="" width="auto" height="100%" class="img-screen" /> -->
      <el-image :src="item.url" :preview-src-list="allUrl" width="auto" height="100%" class="img-screen"></el-image>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
  export default {
    name: 'PicShow',
    props: {
      picInfo: {
        type: Array,
        default: () => []
      },
      myHeight: {
        type: [String],
        default: '100'
      }
    },
    data() {
      return {
        picList: [], // 照片信息列表
        allUrl: [], // url地址
        myht: this.myHeight + 'px'
      }
    },
    computed: {},

    watch: {
      picInfo: {
        immediate: true,
        handler(val, oldval) {
          if (val.length > 0) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description  梳理照片数组数据
       * @author hu_ty
       * @since
       * @param {array} data pic infomation desc
       *
       */
      loadData(data) {
        this.picList = []
        this.allUrl = []
        data.forEach(async (item) => {
          item.url = await this.formmaterPicUrl(item.code)
          this.picList.push(item)
          this.allUrl.push(item.url)
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .img-screen {
    display: block;
    margin: 0 auto;
    aspect-ratio: 16/9;
  }
</style>
