import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const ProvinceTokenKey = 'JIANG-SU-SHENG-PRIVATE-TOKEN'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getProvinceToken() {
  return Cookies.get(ProvinceTokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setProvinceToken(token) {
  return Cookies.set(ProvinceTokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
