import request from '@/utils/request'
const BASE_URL = '/file'
const PROVINCE_BASE_URL = '/jssxfhh'
/**
 * @description  文件相关功能类
 * @author hu_ty
 *
 */
export class File {
  /**
   * @description  文件上传
   * @author hu_ty
   * @since
   * @param {object} params 上传的文件信息和相关的附加信息，formData对象
   * @param {object} callback 文件上传进度条信息的回调函数对象
   *
   */
  static uploadFile = (params, callback) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/file/upload`,
      data: params,
      onUploadProgress: (progressEvent) => {
        // 原生获取上传进度的事件
        if (progressEvent.lengthComputable) {
          callback && callback(progressEvent)
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  /**
   * @description  文件路径查找
   * @author hu_ty
   * @since
   * @param {string} code 文件 code 信息
   *
   */
  static findUrl = (code) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/file/getUrl/` + code
    })
  }
  /**
   * @description  获取oss上传tiken
   * @param
   *
   */
  static get_token = () => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/file/uploadToken`
    })
  }

  /**
   * @description  保存文件信息
   * @param
   *
   */
  static file_info_save = (param) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/file/save`,
      data: param
    })
  }
}

export class ProvinceFile {
  /**
   * @description  文件路径查找
   * @author hu_ty
   * @since
   * @param {string} code 文件 code 信息
   *
   */
  static findUrl = (code) => {
    return request({
      method: 'get',
      url: `${PROVINCE_BASE_URL}/scheme/file/getUrl/` + code
    })
  }
}
