/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return fmt
  }
}

/**
 * @description 时间格式化（yyyy-MM-dd HH:00:00）
 * @author hu_ty
 * @since
 * @param {string} now 1663736400000 时间秒数字符串
 *
 * formatDate(PointData[0].TM.slice(6, 19))
 *
 */
export function formatDate(now) {
  let dates
  if (typeof now === 'object') {
    dates = new Date(now)
  } else {
    dates = new Date(parseInt(now))
  }
  var year = dates.getFullYear() // 取得4位数的年份
  var month = dates.getMonth() + 1 // 取得日期中的月份，其中0表示1月，11表示12月
  var date = dates.getDate() // 返回日期月份中的天数（1到31）
  var hour = dates.getHours() // 返回日期中的小时数（0到23）
  var minute = dates.getMinutes() // 返回日期中的分钟数（0到59）
  var second = dates.getSeconds() // 返回日期中的秒数（0到59）
  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  if (hour < 10) {
    hour = '0' + hour
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  if (second < 10) {
    second = '0' + second
  }
  return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
}
