// import axios from 'axios'
import qs from 'qs'
import request from '@/utils/request'
const BASE_URL = '/wjhhjk'

/**
 * @description 用户
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class User {
  /** 列表查询 */
  static user_list = (params) => {
    return request.post(`${BASE_URL}/scheme/user/listAll`, params)
  }
  //   删除用户
  static del_user = (id) => {
    return request.get(`${BASE_URL}/scheme/user/deleteById/` + id)
  }

  static addUser = (params) => {
    return request.post(`${BASE_URL}/scheme/user/save`, params)
  }
  static update_user = (params) => {
    return request.post(`${BASE_URL}/scheme/user/save`, params)
  }
  static resetUserPwd = (params) => {
    return request.post(`${BASE_URL}/scheme/user/changePwd`, params)
  }
  /**
   * @description  密码重置
   * @author hu_ty
   * @since
   * @param {*}
   *
   */
  static reset_pwd = (id) => {
    return request.get(`${BASE_URL}/scheme/user/resetPwd/` + id)
  }
}

export class Log {
  static getList = (params) => {
    return request.post(`${BASE_URL}/scheme/recordUserLogin/listPage`, params)
  }
}
