/****   request.js   ****/
import { getToken, getProvinceToken } from '@/utils/auth'

// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui'
//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--这里注意后面会讲
  // baseURL: process.env.BASE_API,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 100 * 1000
})
// 2.请求拦截器
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['PRIVATE-TOKEN'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['JIANG-SU-SHENG-PRIVATE-TOKEN'] = getProvinceToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.data.code === 109) {
      let message = response.data.data
      Message({
        message: message,
        type: 'info'
      })
      window.location.href = '/'
    } else if (response.data.code !== 109 && response.data.code !== 0 && response.data.status !== 0) {
      //response.data.status !== 0 针对地图接口
      // alert(response.data.data)
      let message = response.data.data
      // Message({
      //   message: message,
      //   type: 'info'
      // })
      // throw Message.error(message)
      return Promise.reject(message)
    } else {
      return response
    }
    // return response
  },
  (error) => {
    console.log(error)
    /***** 接收到异常响应的处理开始 *****/
    let message = ''
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          message = '错误请求'
          break
        case 401:
          message = '未授权，请重新登录'
          break
        case 403:
          message = '拒绝访问'
          break
        case 404:
          message = '请求错误,未找到该资源'
          // window.location.href = '/NotFound'
          break
        case 405:
          message = '请求方法未允许'
          break
        case 408:
          message = '请求超时'
          break
        case 500:
          message = '服务器端出错'
          break
        case 501:
          message = '网络未实现'
          break
        case 502:
          message = '网络错误'
          break
        case 503:
          message = '服务不可用'
          break
        case 504:
          message = '网络超时'
          break
        case 505:
          message = 'http版本不支持该请求'
          break
        default:
          message = `连接错误${error.response.status}`
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        return Message.error('服务器响应超时，请刷新当前页')
      }
      message = '连接服务器失败'
    }

    Message.error(message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
  }
)
//4.导入文件
export default service
