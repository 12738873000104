import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css' //element-ui的css

import MyUploadFile from '@/components/uploadFile/MyUploadFile.vue' //element-ui的全部组件
import MyUploadImage from '@/components/uploadFile/MyUploadImage.vue'

import './assets/font/font.css'

import '@/assets/css/global.less'

import './utils/directives.js'

import Standrad from '@/views/standardAnchor/standard.vue'

Vue.config.productionTip = false

Vue.use(ElementUI) //使用elementUI

import Print from 'vue-printjs'
Vue.use(Print) // 注册打印功能

import dataV from '@jiaminghi/data-view'
Vue.use(dataV) // 注册大屏动态组件

// 全局组件挂载
import MyTitle from './components/title/MapTitle.vue'
Vue.component('MyTitle', MyTitle)

import NoneTarget from './views/provinceOnline/objPage/noneTarget.vue'
Vue.component('NoneTarget', NoneTarget)

import PicShow from './views/provinceOnline/objPage/picShow.vue'
Vue.component('PicShow', PicShow)

import MySwiper from './components/mySwiper/MySwiper.vue'
Vue.component('MySwiper', MySwiper)

// 全局组件挂载
Vue.component('MyUploadFile', MyUploadFile)
Vue.component('Standrad', Standrad)
Vue.component('MyUploadImage', MyUploadImage)

import { formmaterPicUrl } from '@/utils/commonMethods'
Vue.prototype.formmaterPicUrl = formmaterPicUrl

import { formatDate } from './utils/index'
Vue.prototype.formatDate = formatDate

import { MY_MAP_LEVEL_NUM } from './global.js'
Vue.prototype.MY_MAP_LEVEL_NUM = MY_MAP_LEVEL_NUM

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
