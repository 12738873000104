import request from '@/utils/request'
const BASE_URL = '/wjhhjk'

/**
 * @description 河流流动性指标
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class FluvialFluidity {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/heLiuLiuDongXing/save`,
      data: params
    })
  }

  //   数据加载
  // static loadData = (params) => {
  //   return request({
  //     method: 'post',
  //     url: `${BASE_URL}/target/heLiuLiuDongXing/save`,
  //     data: params
  //   })
  // }
}

/**
 * @description 生态用水满足程度
 * @author hu_ty
 * @since
 * @param {*}
 *
 */

export class EcologicalWater {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shengTaiYongShuiManZuChengDu/save`,
      data: params
    })
  }
}

/**
 * @description  底泥污染状况
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class SedimentPollution {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/diNiWuRanZhuangKuang/save`,
      data: params
    })
  }
}

/**
 * @description  着生藻类生物指数
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class BorneAlgae {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/zheShengZaoLeiShengWuZhiShu/save`,
      data: params
    })
  }
}

/**
 * @description  防洪达标率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class PrevenFlood {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/fangHongDaBiaoLv/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/fangHongDaBiaoLv/findById/` + id
    })
  }
  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/fangHongDaBiaoLv/deleteDetailById/` + id
    })
  }

}

//岸坡稳定性
export class AnPoWenDingXing {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anPoWenDingXing/save`,
      data: params
    })
  }
  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/anPoWenDingXing/findById/` + id
    })
  }
}

//岸坡环境整治程度
export class AnPoHuanJingZhengZhiChengDu {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anPoHuanJingZhengZhiChengDu/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/anPoHuanJingZhengZhiChengDu/findById/` + id
    })
  }
}

//岸坡植被覆盖度
export class AnPoZhiBeiFuGaiDu {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anPoZhiBeiFuGaiDu/save`,
      data: params
    })
  }
  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/anPoZhiBeiFuGaiDu/findById/` + id
    })
  }

  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anPoZhiBeiFuGaiDu/deleteDetailById/` + id
    })
  }

}

//岸线开发利用率
export class AnXianKaiFaLiYongLv {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anXianKaiFaLiYongLv/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/anXianKaiFaLiYongLv/findById/` + id
    })
  }

  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/anXianKaiFaLiYongLv/deleteDetailById/` + id
    })
  }

}

//水体整洁程度
export class ShuiTiZhengJieChengDu {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shuiTiZhengJieChengDu/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/shuiTiZhengJieChengDu/findById/` + id
    })
  }

}

//生态岸坡比例
export class EcologicalBank {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shengTaiAnPoBiLi/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/shengTaiAnPoBiLi/findById/` + id
    })
  }

  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shengTaiAnPoBiLi/deleteDetailById/` + id
    })
  }
}

/**
 * @description  供水水量保证率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class WaterSupply {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/gongShuiShuiLiangBaoZhengLv/save`,
      data: params
    })
  }
}

/**
 * @description  入河排污口规范化建设率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Outlet {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/ruHePaiWuKouGuiFanHuaJianShe/save`,
      data: params
    })
  }

  static delInfo = (pid, did) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/ruHePaiWuKouGuiFanHuaJianShe/deleteDetailById/` + pid + `/` + did
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/ruHePaiWuKouGuiFanHuaJianShe/findById/` + id
    })
  }
}

/**
 * @description  浮游植物生物指数
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Phytoplankton {
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/fuYouZhiWuShengWuZhiShu/save`,
      data: params
    })
  }
}

/**
 * @description  水质优劣程度
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class WaterQuality {
  /** 指标信息保存 */
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shuiZhiYouLieChengDu/save`,
      data: params
    })
  }
}

/**
 * @description  底栖生物指数
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Benthic {
  /** 指标信息保存 */
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/diQiShengWuZhiShu/save`,
      data: params
    })
  }
}

/**
 * @description  管理（保护）范围划定率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class ManagementScope {
  /** 指标信息保存 */
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/guanLiFanWeiHuaDingLv/save`,
      data: params
    })
  }

  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/guanLiFanWeiHuaDingLv/deleteDetailById/` + id
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/guanLiFanWeiHuaDingLv/findById/` + id
    })
  }
}

/**
 * @description  调查问卷
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Questionnaire {
  /** 获取调查问卷 */
  static getInfo = (token) => {
    return request.get(`${BASE_URL}/target/gongZhongManYiDu/getXml/` + token)
  }

  /**
   * @description  获取问卷统计数据
   * @author hu_ty
   * @since
   * @param {number} schemeId 方案Id
   *
   */
  static getCountData = (schemeId) => {
    return request.get(`${BASE_URL}/target/gongZhongManYiDu/statistics/` + schemeId)
  }

  static questionnaireList = (params) => {
    return request.post(`${BASE_URL}/admin/questionnaire/listPage`, params)
  }

  // 获取问卷详情数据

  static questionnaireDetileGet = (params) => {
    return request.post(`${BASE_URL}/target/gongZhongManYiDu/detailSeq`, params)
  }

  // 判定问卷无效

  static nopass = (id) => {
    return request.post(`${BASE_URL}/target/gongZhongManYiDu/fit/` + id)
  }
}

/**
 * @description  水功能区
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class WaterFunArea {
  
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shuiGongNengQuDaBiaoLv/save`,
      data: params
    })
  }

  static delDetail = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/shuiGongNengQuDaBiaoLv/deleteDetailById/`+id,
    })
  }
}

/**
 * @description  景观舒适度
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Landscape {
  /** 保存景观舒适度详情 */
  static saveDeInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/jingGuanShuShiDu/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/jingGuanShuShiDu/findById/` + id
    })
  }
}
