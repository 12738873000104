<template>
  <div>
    <el-upload
      ref="attachs"
      class="upload-demo"
      action=""
      accept="image/jpg,image/jpeg,image/png"
      :before-upload="beforeImageUpload"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :file-list="fileList"
      :http-request="
        (e) => {
          attachupload(e)
        }
      "
      :on-success="
        (response, file, fileList) => {
          uploadSuccess(response, file, fileList)
        }
      "
      :on-error="
        (err, file, fileList) => {
          uploadFail(err, file, fileList)
        }
      "
    >
      <el-button class="upload-btn"
        ><img src="../../assets/icon/project/uploadFile.svg" alt="" srcset="" />
        <div>添加现场照片</div>
      </el-button>
    </el-upload>
    <el-progress v-if="percentShow" :percentage="loadPer"></el-progress>
  </div>
</template>
<script>
import { File } from '@/api/file/index.js'
export default {
  props: {
    imageType: {
      type: String,
      default: () => ''
    },
    attachs: {
      type: String,
      default: () => ''
    },
    queryExt: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fileList: [],
      percentShow: false,
      loadPer: 0,
      myAttachsNow: '',
      successFileList: [],
      attachsfileList: [],
      myAttachs: ''
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.attachsfileList = []
    this.loadData()
  },
  methods: {
    /**
     * @description 加载文件信息
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    loadData() {
      if (this.queryExt && this.queryExt != {}) {
        if (this.attachs && this.attachs != '') {
          this.attachs.split(',').forEach((item) => {
            this.fileList.push(this.queryExt[item])
          })
        }
      }
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      return isJPG
    },
    /**
     * @description 文件删除
     * @author hu_ty
     * @since 22-06-28
     * @param {*}
     *
     */
    handleRemove(file, fileList) {
      // console.log(file, fileList)
      this.fileList = fileList

      this.getAttachs()
    },
    handlePreview(file) {
      console.log(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    /**
     * @description 文件上传
     * @author hu_ty
     * @since 22/06/28
     * @param {*} file: 上传的文件
     *
     */
    async attachupload({ file, onSuccess, onError }) {
      console.log('', file)
      try {
        this.percentShow = true
        let form = new FormData()
        form.append('file', file)
        let response = await File.uploadFile(form, this.updateProgress)
        onSuccess()
        this.fileList.push(response.data.data)
        let path = ''
        path = response.data.data.fileCode
        this.attachsfileList.push(path)
        this.myAttachsNow = this.attachsfileList.toString()

        this.getAttachs()
      } catch (err) {
        console.log('err', err)
        onError()
      }
    },
    /**
     * @description 文件上传进度内容监听
     * @author hu_ty
     * @since 22-06-28
     * @param {*} e axios 进度回调事件
     *
     */
    updateProgress(e) {
      this.loadPer = parseInt((e.loaded / e.total) * 100)
      // 关闭进度条
      if (this.loadPer === 100) {
        setTimeout((_) => ((this.percentShow = false), (this.loadPer = 0)), 500)
      }
    },
    uploadSuccess(res, file, fileList) {
      fileList.forEach((item) => {
        if (file.uid === item.uid) {
          item.status = 'success'
        }
      })
      this.percentShow = false
    },
    uploadFail(res, file, fileList) {
      fileList.forEach((item) => {
        if (file.uid === item.uid) {
          item.status = 'fail'
        }
      })
    },
    /**
     * @description 设置组件内容信息重置
     * @author hu_ty
     * @since 22-06-28
     * @param {*}
     *
     */
    setFileList() {
      this.successFileList = []
      this.fileList = []
    },

    getAttachs() {
      let arr = []
      this.myAttachs = ''
      if (this.fileList.length > 0) {
        this.fileList.forEach((item) => {
          arr.push(item.fileCode)
        })
      } else {
        arr = []
      }
      this.myAttachs = arr.toString()

      if (this.imageType) {
        console.log('imageType', this.imageType, this.myAttachs)
        this.$emit('getAttachs', { imageType: this.imageType, attach: this.myAttachs })
      }
      return this.myAttachs
    }
  }
}
</script>
<style lang="less" scoped>
.el-button {
  width: 108px;
  height: 32px;
  // line-height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/ span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    div {
      margin-left: 3px;
    }
  }
}

.el-button:focus,
.el-button:hover {
  color: #000;
  border-color: #eee;
  background-color: #eee;
}

::v-deep .el-upload-list__item {
  width: 90% !important;
}
</style>
