<template>
  <div class="page">
    <div class="top-bg"></div>
    <!-- <img class="bg-img" src="../assets/header-background-green.png" alt="" /> -->
    <div class="top">
      <div class="timeDiv">
        <img src="../assets/time-icon.svg" alt="" />
        <div class="timeer">{{ dateYear }}&nbsp;{{ dateDay }}</div>
        <div class="split"></div>
        <div class="name">
          {{ user ? user.name : '' }}
        </div>
        <el-dropdown style="height: 24px; line-height: 24px" @command="handleCommand">
          <img src="../assets/loginOut.svg" alt="" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="person">修改密码</el-dropdown-item>
            <el-dropdown-item command="logoOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="title-box-layout">
        <span class="textTitlle">吴江河湖健康管理</span>
      </div>
      <div class="btn btn-line" @click="go('/online/map')">健康在线</div>
      <div class="btn btn-assess" :class="!path.includes('admin') ? 'btn-in' : ''">
        健康评估
        <div class="ul-list">
          <div></div>
          <ul>
            <li @click="go('/createPro')">创建方案</li>
            <li @click="go('/indicatorEvaluate')">指标评价</li>
            <li @click="go('/reportManagement')">评估报告</li>

            <!-- <li @click="go()">健康评估</li>
            <li @click="go()">报告编制</li> -->
          </ul>
        </div>
      </div>
      <div class="btn btn-manage">健康管理</div>
      <div v-if="user.type == 'admin'" class="btn btn-system" :class="path.includes('admin') ? 'btn-in' : ''">
        系统管理
        <div class="ul-list">
          <div></div>
          <ul>
            <li @click="go('/admin/user')">用户管理</li>
            <li @click="go('/admin/log')">登录日志</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="body-content">
      <router-view style="z-index: 0"></router-view>
    </div>
    <el-drawer title="修改密码" :visible.sync="drawer" direction="rtl">
      <el-row :gutter="20" style="margin: 12px 0">
        <el-form ref="form" :model="objInfo" :rules="form" size="small" label-width="160px" label-position="top">
          <el-col :span="16">
            <el-form-item label="登录密码" prop="pwd">
              <el-input
                v-model="objInfo.pwd"
                placeholder="请输入登录密码"
                clearable
                prefix-icon="el-icon-lock"
                show-password
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="确认密码" prop="pwd2">
              <el-input
                v-model="objInfo.pwd2"
                placeholder="请输入确认密码"
                clearable
                show-password
                prefix-icon="el-icon-lock"
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="form-btn-group" style="margin-left: 12px">
        <el-button class="confirm" type="primary" @click="confirm()">确认</el-button>
        <el-button class="cancel" @click="cancel()">取消</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  import { formatTime } from '@/utils/index'
  import { User, Login } from '@/api/login/login'
  import { User as UserSys } from '@/api/system/index'

  export default {
    name: '',
    props: {},
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.objInfo.pwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        dateYear: null,
        dateDay: null,
        user: {},
        path: null, // 路由路径
        drawer: false,
        objInfo: {
          pwd: undefined,
          pwd2: undefined
        },
        form: {
          pwd: [
            {
              required: true,
              message: '请输入登录密码',
              trigger: 'blur'
            }
          ],
          pwd2: [
            {
              validator: validatePass2,
              required: true,
              // message: '请输入确认密码',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    computed: {},
    watch: {},
    created() {
      this.path = this.$route.fullPath

      this.getUser()
    },
    mounted() {
      /** 时钟函数 */
      this.timeFn()
    },
    unMounted() {
      clearInterval(this.timing)
    },
    methods: {
      /**
       * @description 获取当前用户信息
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      getUser() {
        User.getInfo().then((res) => {
          this.user = res.data.data
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
        })
      },
      /** 时间函数 */
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH:mm:ss')
          this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
        }, 1000)
      },
      /** 设置下拉选触发按钮 */
      handleCommand(command) {
        if (command == 'person') {
          this.drawer = true
        } else if (command == 'logoOut') {
          this.loginOut()
        }
      },
      /**
       * @description  修改密码
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      confirm() {
        let params = {
          id: this.user.id,
          pwd: this.objInfo.pwd
        }
        UserSys.resetUserPwd(params).then((response) => {
          if (response.data.code === 0) {
            this.$message('修改密码成功，请重新登录！')

            Login.loginOut().then(() => {
              this.$router.push({ path: '/login' })
            })
          } else {
            this.$message(response.data.data)
          }
        })
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      go(val) {
        this.path = val
        if (this.$route.path !== val) {
          this.$router.push({
            path: val
          })
        }
      },
      /**
       * @description 退出登录
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      async loginOut() {
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          Login.loginOut().then(() => {
            this.$router.push({ path: '/login' })
          })
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .page {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
  }
  .body-content {
    width: 100%;
    height: calc(100% - 64px);
  }

  .top-bg {
    height: 64px;
    width: 100%;
    display: flex;
    background: rgb(2, 71, 83);
    opacity: 0.1;
    backdrop-filter: blur(20px);
  }
  .top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    line-height: 80px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    background-image: url('../assets/header-background-green.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    .timeDiv {
      position: fixed;
      // right: 1.25vw;
      right: 25px;
      height: 66px;
      line-height: 66px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      img:first-child {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .split {
        height: 24px;
        width: 1px;
        background-color: rgb(255, 255, 255);
        margin: 0 24px;
      }
      .name {
        margin-right: 8px;
        font-size: 18px;
      }
    }
    .title-box-layout {
      position: absolute;
      left: 24px;
      .textTitlle {
        font-family: 'title-font';
        color: rgb(255, 255, 255);
        font-weight: 500;
        font-size: 44px;
        letter-spacing: 7px;
      }
    }
    .btn {
      position: absolute;
      width: 140px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      top: 33px;
      transform: translateY(-50%);
      font-size: 18px;
      color: rgba(255, 255, 255, 0.5);
      background-image: url('../assets/btn.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
    }
    .btn-line {
      left: 526px;
    }
    .btn-assess {
      left: 682px;
    }
    .btn-manage {
      left: 838px;
    }
    .btn-system {
      left: 994px;
    }
    .btn-in {
      background-image: url('../assets/btn-sec.png');
      font-weight: 500;
      color: rgb(255, 255, 255);
    }
  }

  .ul-list {
    color: #000;
    text-align: center;
    display: none;
    div {
      height: 20px;
    }
    ul {
      background: rgba(255, 255, 255, 1);
      backdrop-filter: blur(20px);
      list-style: none;
      padding: 8px 8px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      width: 124px;
      li {
        margin: 0 auto;
        margin-bottom: 8px;
        width: 108px;
        height: 36px;
        line-height: 36px;
      }
      li:hover {
        background: rgba(64, 158, 255, 0.1);
        color: rgb(64, 158, 255);
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .btn-assess:hover .ul-list {
    display: block;
  }

  .btn-system:hover .ul-list {
    display: block;
  }
</style>
