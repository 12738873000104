/**
 * @description 存放全局变量
 *
 */

const mapLevelListEnum = ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7']

const rand = (min = 0, max = 1) => Math.round(Math.random() * (max - min) + min)

export const MY_MAP_LEVEL_NUM = mapLevelListEnum[rand(0, mapLevelListEnum.length - 1)]

// console.log('<==  ==>', MY_MAP_LEVEL_NUM, rand(0, mapLevelListEnum.length - 1))
// export default {
//   MY_MAP_LEVEL_NUM
// }
