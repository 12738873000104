<template>
  <div class="swiper-container">
    <div :options="swiperOptions" class="swiper-wrapper">
      <div v-for="(item, index) in UrlList" :key="index" class="swiper-slide">
        <img :src="item.url" />
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div> -->
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/js/swiper.js"></script>

<script>
  export default {
    name: 'MySwiper',
    components: {},
    props: {
      UrlList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        swiperOptions: {
          autoplay: 2000, //自动滑动
          speed: 500, //自动滑动开始到结束的时间（单位ms）
          loop: true, //开启循环
          loopedSlides: 1, //在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
          slidesPerView: '2', //设置slider容器能够同时显示的slides数量(carousel模式)。另外，支持'auto'值，会根据容器container的宽度调整slides数目。
          effect: 'coverflow', //可以实现3D效果的轮播,
          pagination: '.swiper-pagination', //分页器
          centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
          coverflow: {
            rotate: 50, //slide做3d旋转时Y轴的旋转角度。默认50。
            stretch: 10, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
            depth: 400, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
            slideShadows: false //开启slide阴影。默认 true。
          }
        },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      new Swiper('.swiper-container', {
        autoplay: 2000, //自动滑动
        speed: 1000, //自动滑动开始到结束的时间（单位ms）
        loop: true, //开启循环
        loopedSlides: 2, //在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
        slidesPerView: '2', //设置slider容器能够同时显示的slides数量(carousel模式)。另外，支持'auto'值，会根据容器container的宽度调整slides数目。
        effect: 'coverflow', //可以实现3D效果的轮播,
        pagination: '.swiper-pagination', //分页器
        centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
        coverflow: {
          rotate: 40, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -100, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 1000, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          slideShadows: false //开启slide阴影。默认 true。
        }
      })
    },
    methods: {}
  }
</script>
<style lang="less" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/css/swiper.css');

.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.swiper-container img {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  margin: 0 auto;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet-active {
  background-color: #ffd200;
}
</style>
