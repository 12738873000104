<template>
  <el-dialog
    title="指标描述"
    :visible.sync="flag"
    width="60%"
    :before-close="close"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div slot="title">
      <span class="title-name"> 指标描述 </span>
      <el-button id="btn" type="text" @click="goAchor">跳转对应指标</el-button>
    </div>
    <div class="anchor">
      <h1 id="apwdx">岸坡稳定性</h1>
      <img src="./anpowendingxing.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="apzbfgd">岸坡植被覆盖度</h1>
      <img src="./anpozhibeifugaidu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="hlldx">河流流动性</h1>
      <img src="./heliuliudongxing.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="stysmzcd">生态用水满足程度</h1>
      <img src="./shengtaiyongshuimanzuchengdu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="szylcd">水质优劣程度</h1>
      <img src="./shuizhiyouliechengdu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="dnwrzk">底泥污染状况</h1>
      <img src="./diniwuranzhuangkuang.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="dxswzs">底栖生物指数</h1>
      <img src="./diqishengwuzhishu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="fyzwswzs">浮游植物生物指数</h1>
      <img src="./fuyouzhiwushengwuzhishu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="zszlswzs">着生藻类生物指数</h1>
      <img src="./zhuoshengzaoleishengwuzhishu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="fhdbl">防洪达标率</h1>
      <img src="./fanghongdabiaolv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="sgnqdbl">水功能区达标率</h1>
      <img src="./shuigongnengqudabiaolv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="gsslbzl">供水水量保证率</h1>
      <img src="./gongshuishuiliangbaozhenglv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="yyssydszdbl">饮用水水源地水质达标率</h1>
      <img src="./yinyongshuishuiyuandishuizhidabiaolv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="jgssd">景观舒适度</h1>
      <img src="./jingguanshushidu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="gzmyd">公众满意度</h1>
      <img src="./gongzhongmanyidu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="axkflyl">岸线开发利用率</h1>
      <img src="./anxiankaifaliyonglv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="rhpwkgfhjsl">入河排污口规范化建设率</h1>
      <img src="./ruhepaiwukouguifanhuajianshelv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="glbhfwhdl">管理（保护）范围划定率</h1>
      <img src="./guanlibaohufanweihuadinglv.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="aphjzzcd">岸坡环境整治程度</h1>
      <img src="./anpohuanjingzhengzhichengdu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="stzjcd">水体整洁程度</h1>
      <img src="./shuitizhengjiechengdu.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="stapbl">生态岸坡比例</h1>
      <img src="./shengtaianpobili.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="kmctl">口门畅通率</h1>
      <img src="./koumenchangtonglv.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="hbdzbfgd">湖滨带植被覆盖度</h1>
      <img src="./hubindaizhibeifugaidu.png" alt="" srcset="" />
      ==========>
      <img src="./anpozhibeifugaidu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="hsjhnl">湖水交换能力</h1>
      <img src="" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="stswmzl">生态水位满足率</h1>
      <img src="./shengtaishuiweimanzulv.png" alt="" />
    </div>
    <div class="anchor">
      <h1>水质优劣程度</h1>
      <img src="./huposhuizhiyouliechengdu.png" alt="" />
      ==========>
      <img src="./shuizhiyouliechengdu.png" alt="" srcset="" />
    </div>
    <div class="anchor">
      <h1 id="yyztzs">营养状态指数</h1>
      <img src="./yinyangzhuangtaizhishu.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="hpdnwrzk">底泥污染状况</h1>
      <img src="./hupodiniwuranzhuangkuang.png" alt="" />
      ==========>
      <img src="./diniwuranzhuangkuang.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="zyrhhlazdbl">主要入湖河流水质达标率</h1>
      <img src="./zhuyaoruhuheliushuizhidabiaolv.png" alt="" />
      ==========>
      <img src="./shuigongnengqudabiaolv.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="lzmd">蓝藻密度</h1>
      <img src="./lanzaomidu.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="smkflyl">水面开发利用率</h1>
      <img src="./shuimiankaifaliyongl.png" alt="" />
    </div>
    <div class="anchor">
      <h1 id="hpglbhfwhdl">湖泊管理（保护）范围划定率</h1>
      <img src="./hupoguanlibaohufanweihuadinglv.png" alt="" />
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'Standrad',
    components: {},
    props: {
      anchor: {
        type: String,
        default: ''
      },
      showFlag: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        flag: false
      }
    },
    computed: {},
    watch: {
      showFlag: {
        immediate: true,
        handler(val) {
          this.flag = this.showFlag
          // this.goAchor()
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      close() {
        this.$emit('changFlag')
        this.flag = false
      },
      goAchor() {
        console.log('<== 指标锚点id ==>', this.anchor)
        document.querySelector('#' + this.anchor).scrollIntoView(true)
      }
    }
  }
</script>
<style lang="less" scoped>
  .title-name {
    font-size: 20px;
    color: #000;
  }
  h1 {
    font-size: 18px;
    color: #000;
  }
  .anchor {
    width: 98%;
    height: auto;
    padding: 16px;
    img {
      width: 98%;
    }
  }
  /deep/ .el-dialog__body {
    height: 600px;
    overflow: auto;
  }
</style>
