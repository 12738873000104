/**
 * @description  依照传入的 codes 返回 url 地址数组
 * @author hu_ty
 * @since
 * @param {string} pngs code 字段（图片地址 code 数据）
 *
 */

import { ProvinceFile } from '@/api/file/index.js'

export async function formmaterPicUrl(code) {
  let url = ''
  if (code && !code.includes('tmp')) {
    await ProvinceFile.findUrl(code).then((res) => {
      url = res.data.data
    })
  }
  return url
}
