<template>
  <div style="display: flex; align-items: center; flex-direction: column; justify-content: center; width: 100%">
    <img src="../../../assets/icon/notarget.svg" alt="" width="108px" />
    <div style="color: #fff">此项指标本次未选用评判</div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped></style>
