const createPro = {
  state: {
    basicInfo: {}
  },

  mutations: {
    setBasicInfo(state, address) {
      console.log('<== state ==>', address)
      state.basicInfo = Object.assign(state.basicInfo, address)
    }
  },

  actions: {}
}

export default createPro
