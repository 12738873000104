import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import LayoutHome from '@/layout/layoutMap.vue'

import Questionnaire from '@/views/healthAssessment/IndicatorEvaluate/questionnaire/questionnaire.vue'
import SuccessPage from '@/views/healthAssessment/IndicatorEvaluate/questionnaire/submitSucess.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login.vue'], resolve)
  },
  {
    path: '',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/createPro',
        name: 'CreatePro',
        component: (resolve) => require(['@/views/healthAssessment/createProject/index.vue'], resolve),
        meta: {
          title: '创建项目'
        }
      },
      {
        path: '/indicatorEvaluate',
        name: 'IndicatorEvaluate',
        component: (resolve) => require(['@/views/healthAssessment/IndicatorEvaluate/index.vue'], resolve),
        meta: {
          title: '指标评估'
        }
      },
      {
        path: '/reportManagement',
        name: 'Report',
        component: (resolve) => require(['@/views/healthAssessment/report/index.vue'], resolve),
        meta: {
          title: '报告管理'
        }
      },
      {
        path: '/admin/user',
        name: 'User',
        component: (resolve) => require(['@/views/system/user.vue'], resolve),
        meta: {
          title: '用户管理'
        }
      },
      {
        path: '/admin/log',
        name: 'User',
        component: (resolve) => require(['@/views/system/log.vue'], resolve),
        meta: {
          title: '日志管理'
        }
      }
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: (resolve) => require(['@/views/test.vue'], resolve),
    meta: {
      title: ''
    }
  },
  {
    path: '/questionnaire',
    component: Questionnaire
  },
  {
    path: '/submit',
    component: SuccessPage,
    hidden: true
  },
  {
    path: '/online',
    name: 'LayoutHome',
    component: LayoutHome,
    children: [
      {
        path: '/online/map',
        name: '',
        component: (resolve) => require(['@/views/onlineIndex.vue'], resolve)
      }
    ]
  },
  {
    path: '/qrcode/:id/:name/:year',
    component: () => import('@/views/online/qrcode.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach()

export default router
