// import axios from 'axios'
import qs from 'qs'
import request from '@/utils/request'
// import { netAddress } from '../../global'
const BASE_URL = '/wjhhjk'
const PROVINCE_BASE_URL = '/jssxfhh'

/**
 * @description 账户登录功能
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Login {
  /** 登录 */
  static loginIn = (params) => {
    return request.post(`${BASE_URL}/login`, qs.stringify(params))
  }

  //退出登录
  static loginOut = () => {
    return request.get(`${BASE_URL}/logout`)
  }
}

/**
 * @description 省级账户登录功能
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class ProvinceLogin {
  /** 登录 */
  static loginIn = (authtoken) => {
    return request.get(`${PROVINCE_BASE_URL}/scheme/user/exchangeByAuthtoken/` + authtoken)
  }

  //退出登录
  static loginOut = () => {
    return request.get(`${BASE_URL}/logout`)
  }
}

/**
 * @description 用户信息获取
 * @author hu_ty
 * @since
 * @param {*}
 *
 */

export class User {
  /** 获取当前登录用户信息 */
  static getInfo = () => {
    return request.get(`${BASE_URL}/scheme/user/findCurrentUser`)
  }
}
